<template>
  <section>
    <PageHeader :title="$t('sparkpay.wallet.title')" :subTitle="$t('sparkpay.wallet.description')" />
    <WalletNavigation class="navigation" />
    <div class="content">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import PageHeader from '@/components/_structures/PageHeader.vue';
import WalletNavigation from './components/WalletNavigation.vue';

export default {
  name: 'Wallet',
  components: {
    WalletNavigation,
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
/deep/ .navigation {
  margin-top: 1px;
}

.content {
  padding: 32px;
}

@media screen and (max-width: 768px) {
  .content {
    padding: 16px;
  }
}
</style>

<template>
  <Modal
    id="anticipation-modal"
    content-class="anticipation-modal-content"
    body-class="anticipation-modal-body"
    hide-header
    no-close-on-esc
    @show="handleDefaultAnticipationValue"
    @hidden="cancelEdit"
  >
    <div tabindex="0" class="anticipation-modal__close-button" @click="$emit('close')">
      <hs-icon icon="times" />
    </div>

    <div class="anticipation-modal__content">
      <div class="anticipation-modal__title-wrapper">
        <h5 class="anticipation-modal__title" data-testid="anticipation-modal-title">
          Solicitação de antecipação
        </h5>

        <p class="anticipation-modal__subtitle">
          Abaixo você pode acompanhar os valores de antecipação
        </p>
      </div>

      <div v-if="isLoadingCalculationRequest" class="anticipation-modal__loading-values-wrapper">
        <LoadingCircle :size="56" color="#ff305c" shadow-color="#ffdcd5" />
      </div>
      <div v-else class="anticipation-modal__values-wrapper">
        <div class="anticipation-modal__calculation-data-wrapper">
          <div
            :class="{ 'anticipation-modal__calculation-data-row--fluid': isEditMode }"
            class="anticipation-modal__calculation-data-row"
          >
            <div>
              Valor máximo de antecipação
            </div>
            <div class="anticipation-modal__value-edit-wrapper">
              <div v-if="isEditMode" class="anticipation-modal__edit-mode">
                <Money
                  id="anticipation-value-input"
                  :value="actualMaxAnticipationValueCentsDuringEdit / 100"
                  prefix="R$"
                  decimal=","
                  thousands="."
                  class="anticipation-modal__edit-input"
                  @input="setAnticipationValue"
                />

                <div class="anticipation-modal__edit-buttons-wrapper">
                  <MXButton icon="fal fa-check" @click="confirmEdit" />
                  <MXButton icon="fal fa-times" @click="cancelEdit" />
                </div>
              </div>
              <div v-else v-b-tooltip.hover.left="anticipationValueTooltip" class="anticipation-modal__value-highlight">
                <span class="anticipation-modal__value-signal">+</span>
                {{ formatCentsToCurrency(actualMaxAnticipationValueCents) }}
              </div>

              <MXButton v-if="!isEditMode" variant="secondary" size="small" @click="enterEditMode">
                Editar valor
              </MXButton>
            </div>
          </div>

          <div class="anticipation-modal__calculation-data-row">
            <div>
              Total de taxas
            </div>
            <div class="anticipation-modal__fees-wrapper">
              <div class="anticipation-modal__value-highlight">
                <span class="anticipation-modal__value-signal anticipation-modal__value-signal--negative">
                  -
                </span>
                {{ formatCentsToCurrency(withdrawAnticipationFees) }}
              </div>

              <span class="anticipation-modal__fees-composition">
                {{ feesCompositionText }}
              </span>
            </div>
          </div>
        </div>

        <hr class="anticipation-modal__divider" />

        <div class="anticipation-modal__anticipation-summary">
          Valor a ser antecipado

          <div class="anticipation-modal__value-highlight">
            = {{ formatCentsToCurrency(actualAnticipationValueCents) }}
          </div>
        </div>
      </div>

      <div class="anticipation-modal__footer">
        <div class="anticipation-modal__faq">
          <hs-icon variant="light" icon="info-circle" size="24" />

          <p>
            Para saber mais sobre a antecipação e tirar demais dúvidas,
            <a
              href="https://ajuda.herospark.com/hc/pt-br/articles/6019620182669-O-que-%C3%A9-antecipa%C3%A7%C3%A3o-e-como-solicitar"
              target="_blank"
            >
              consulte nossa FAQ.
            </a>
          </p>
        </div>

        <div class="anticipation-modal__buttons-wrapper">
          <MXButton
            data-testid="close-button"
            label="Cancelar"
            variant="secondary"
            class="anticipation-modal__cancel-button"
            @click="$emit('close')"
          >
            Cancelar
          </MXButton>

          <div v-b-tooltip.hover.top="invalidAnticipationTooltipText" class="anticipation-modal__send-request-button">
            <MXButton
              data-testid="send-request-button"
              :disabled="isLoadingAnticipationRequest || isInvalidAnticipation"
              behavior="block"
              @click="requestAnticipation"
            >
              Enviar solicitação
            </MXButton>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import sparkPayService from '@/sparkpay/services/sparkpay';
import GeneralHelper from '@/shared/helpers/general';
import Modal from '@/components/Modal.vue';
import MXButton from '@/shared/components/MXButton.vue';
import LoadingCircle from '@/components/LoadingCircle.vue';
import { Money } from 'v-money';

export default {
  name: 'AnticipationModal',
  components: {
    Modal,
    LoadingCircle,
    Money,
    MXButton,
  },
  props: {
    maxAnticipationValueCents: {
      type: Number,
      required: true,
    },
    bankAccountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingAnticipationRequest: false,
      isLoadingCalculationRequest: false,
      actualMaxAnticipationValueCents: 0,
      actualAnticipationValueCents: 0,
      anticipationFeesCents: 0,
      fixWithdrawalFeesCents: 0,
      isEditMode: false,
      actualMaxAnticipationValueCentsBeforeEdit: 0,
      actualMaxAnticipationValueCentsDuringEdit: 0,
      anticipationValueTooltip: 'O valor foi reajustado de acordo com o valor de cada parcela e/ou transação.',
    };
  },
  computed: {
    feesCompositionText() {
      const withdrawFees = this.formatCentsToCurrency(this.fixWithdrawalFeesCents, false);
      const anticipationFees = this.formatCentsToCurrency(this.anticipationFeesCents, false);

      return `Saque ${withdrawFees} + Antecipação ${anticipationFees}`;
    },
    withdrawAnticipationFees() {
      return this.anticipationFeesCents + this.fixWithdrawalFeesCents;
    },
    isInvalidAnticipation() {
      return this.actualAnticipationValueCents <= 0;
    },
    invalidAnticipationTooltipText() {
      return this.isInvalidAnticipation ? 'O valor de antecipação solicitado é abaixo do permitido' : '';
    },
  },
  methods: {
    setAnticipationValue(value) {
      this.actualMaxAnticipationValueCentsDuringEdit = Math.round(Number(value.toFixed(2)) * 100);
    },
    async requestAnticipation() {
      try {
        this.isLoadingAnticipationRequest = true;

        await sparkPayService.withdraw({
          id: this.bankAccountId,
          amount_cents: this.actualMaxAnticipationValueCents,
          withdrawal_type: 'anticipation',
        });

        this.$emit('success-anticipation');
      } catch (error) {
        this.$emit('error-anticipation');
      } finally {
        this.isLoadingAnticipationRequest = false;
      }
    },
    async calculateAnticipationValues() {
      try {
        this.isLoadingCalculationRequest = true;

        const response = await sparkPayService.getAnticipationValue(this.actualMaxAnticipationValueCents);

        this.anticipationFeesCents = response.anticipation_fees;
        this.fixWithdrawalFeesCents = response.fix_withdrawal_fee;
        this.actualMaxAnticipationValueCents = response.maximum_anticipation;
        this.actualAnticipationValueCents = Math.round(Number(response.total_amount_of_anticipation.toFixed(2)) * 100);
      } catch (error) {
        this.$emit('error-anticipation');
      } finally {
        this.isLoadingCalculationRequest = false;
      }
    },
    handleDefaultAnticipationValue() {
      this.actualMaxAnticipationValueCents = this.maxAnticipationValueCents;
      this.calculateAnticipationValues();
    },
    formatCentsToCurrency(value, currency = true) {
      return value ? GeneralHelper.currency(value / 100, currency) : 'R$ 0,00';
    },
    enterEditMode() {
      this.isEditMode = true;
      this.actualMaxAnticipationValueCentsBeforeEdit = this.actualMaxAnticipationValueCents;
      this.actualMaxAnticipationValueCentsDuringEdit = this.actualMaxAnticipationValueCents;
    },
    confirmEdit() {
      this.isEditMode = false;
      this.actualMaxAnticipationValueCents = this.actualMaxAnticipationValueCentsDuringEdit;
      this.calculateAnticipationValues();
    },
    cancelEdit() {
      this.isEditMode = false;
      this.actualMaxAnticipationValueCents = this.actualMaxAnticipationValueCentsBeforeEdit;
    },
  },
};
</script>

<style lang="scss">
.anticipation-modal-content {
  max-width: 448px;
}

.modal .anticipation-modal-body {
  padding: 16px;

  @media (min-width: $screen-md) {
    padding: 32px 24px;
  }
}

.anticipation-modal__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  color: $grey;
  cursor: pointer;

  @media (min-width: $screen-md) {
    top: 20px;
    right: 20px;
  }
}

.anticipation-modal__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: $screen-md) {
    row-gap: 24px;
  }
}

.anticipation-modal__title-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media (min-width: $screen-md) {
    align-items: center;
    row-gap: 0;
  }
}

.anticipation-modal__title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.563rem;
}

.anticipation-modal__subtitle {
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin: 0;
}

.anticipation-modal__values-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.anticipation-modal__loading-values-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 186px;

  @media (min-width: $screen-md) {
    height: 212px;
  }
}

.anticipation-modal__value-highlight {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: $grey-dark;
  text-align: end;

  @media (min-width: $screen-md) {
    font-size: 1.25rem;
    line-height: 1.563rem;
  }
}

.anticipation-modal__divider {
  margin: 0;
  border: 0;
  border-top: 1px solid #cfcfcf;
}

.anticipation-modal__anticipation-summary {
  display: flex;
  justify-content: space-between;
}

.anticipation-modal__edit-input {
  padding: 8px 16px;
  border: 2px solid #bababa;
  color: #262626;
  font-size: 0.875rem;
  border-radius: 4px;
}

.anticipation-modal__edit-mode {
  display: grid;
  grid-template-columns: 58% 1fr;
  column-gap: 16px;
  width: 100%;

  @media (min-width: $screen-md) {
    column-gap: 8px;
    grid-template-columns: 129px 1fr;
  }
}

.anticipation-modal__edit-buttons-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  width: 100%;
  align-items: center;
}

.anticipation-modal__value-signal {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #348535;

  &--negative {
    color: #db3939;
  }

  @media (min-width: $screen-md) {
    font-size: 1.25rem;
    line-height: 1.563rem;
  }
}

.anticipation-modal__value-edit-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: flex-end;

  @media (min-width: $screen-md) {
    row-gap: 8px;
  }
}

.anticipation-modal__calculation-data-wrapper {
  display: grid;
  grid-template-rows: 68px auto;
  row-gap: 20px;

  @media (min-width: $screen-md) {
    grid-template-rows: 73px auto;
    row-gap: 24px;
  }
}

.anticipation-modal__calculation-data-row {
  display: grid;
  grid-template-columns: 110px 1fr;

  @media (min-width: $screen-md) {
    grid-template-columns: 139px 1fr;
  }
}

.anticipation-modal__calculation-data-row--fluid {
  @media (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }
}

.anticipation-modal__fees-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.anticipation-modal__fees-composition {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $grey-40;
}

.anticipation-modal__footer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: $screen-md) {
    row-gap: 32px;
  }
}

.anticipation-modal__faq {
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 16px;
  background-color: $grey-16;
  border-radius: 4px;
  color: $color-neutral-600;

  p {
    font-size: 0.875rem;
    line-height: 1.313rem;
    margin: 0;
  }

  a {
    font-size: 0.875rem;
    color: $purple-dark;
    text-decoration: underline;
  }
}

.anticipation-modal__buttons-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (min-width: $screen-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.anticipation-modal__cancel-button {
  order: 2;

  @media (min-width: $screen-md) {
    order: 1;
  }
}

.anticipation-modal__send-request-button {
  order: 1;

  @media (min-width: $screen-md) {
    order: 2;
  }
}
</style>

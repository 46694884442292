<template>
  <div>
    <div v-if="!isSolicitation && !widthdrawCreated && !widthdrawError">
      <div class="tlw-text-white tlw-p-12" :class="[invalidWithdraw() ? 'bg-cherry' : 'tlw-bg-green-500']">
        <p>{{ $t('sparkpay.wallet.withdraw-modal.solicitation.type-value') }}</p>
        <h2 class="tlw-flex">
          <span class="tlw-text-white-50">R$</span>
          <money
            v-model="total"
            type="tel"
            decimal=","
            thousands="."
            prefix=""
            suffix=""
            :precision="2"
            :masked="false"
            class="tlw-ml-2 tlw-border-0 tlw-p-0 tlw-text-white number bg-transparent w-50"
            v-if="accountData.available !== 0"
          />
          <span v-else class="tlw-ml-2">—</span>
        </h2>
        <div class="tlw-flex tlw-mt-6">
          <div class="tlw-w-full tlw-text-center tlw-border tlw-border-white rounded-lg tlw-py-1 tlw-px-2 tlw-mr-2">
            <p class="font-size-sm tlw-mb-0">
              {{ $t('sparkpay.wallet.withdraw-modal.solicitation.min-value-transfer') }}
              <strong>R$ {{ minValueWithdraw | currency(true) }}</strong>
            </p>
          </div>
          <div class="tlw-w-full tlw-text-center tlw-border tlw-border-white rounded-lg tlw-py-1 tlw-px-2">
            <p class="font-size-sm tlw-mb-0">
              {{ $t('sparkpay.wallet.withdraw-modal.solicitation.max-value-transfer') }}
              <strong>R$ {{ calculatedAvailable | currency(true) }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row tlw-p-6 no-gutters tlw-items-center">
          <div class="col tlw-text-gray-500 tlw-text-center">
            <p class="font-size-sm tlw-mb-1">
              {{ $t('sparkpay.wallet.withdraw-modal.solicitation.stages.transferred') }}
            </p>
            R$ <strong>{{ total | currency(true) }}</strong>
          </div>
          <hs-icon icon="chevron-right" class="tlw-text-gray-500" />
          <div class="col tlw-text-gray-500 tlw-text-center">
            <p class="font-size-sm tlw-mb-1">{{ $t('sparkpay.wallet.withdraw-modal.solicitation.stages.cost') }}</p>
            R$ <strong>{{ calculatedCost | currency(true) }}</strong>
          </div>
          <hs-icon icon="chevron-right" class="tlw-text-gray-500" />
          <div class="col tlw-text-center">
            <p class="font-size-sm tlw-mb-1">{{ $t('sparkpay.wallet.withdraw-modal.solicitation.stages.exit') }}</p>
            R$ <strong>{{ calculatedOutflow | currency(true) }}</strong>
          </div>
        </div>
      </div>
      <div class="tlw-p-6 tlw-border-top tlw-border-bottom">
        <div class="tlw-flex">
          <hs-icon variant="light" icon="university" />
          <div class="tlw-ml-6">
            <h5 class="tlw-font-bold tlw-mb-6">{{ accountData.bank_account.bank_code | bank_name }}</h5>
            <h6 class="tlw-mb-2" v-if="accountData.bank_account.document_type === 'cpf'">
              CPF: <strong>{{ accountData.bank_account.document_number | cpf }}</strong>
            </h6>
            <h6 class="tlw-mb-2" v-if="accountData.bank_account.document_type === 'cnpj'">
              CNPJ: <strong>{{ accountData.bank_account.document_number | cnpj }}</strong>
            </h6>
            <h6 class="tlw-mb-2">
              {{ $t('sparkpay.wallet.withdraw-modal.solicitation.bank-account.agency') }}:
              <strong>{{ accountData.bank_account.agency }}-{{ accountData.bank_account.agency_code }}</strong>
            </h6>
            <h6>
              {{ $t('sparkpay.wallet.withdraw-modal.solicitation.bank-account.account') }}:
              <strong>{{
                accountData.bank_account.account | bank_account(accountData.bank_account.account_vd, false)
              }}</strong>
            </h6>
          </div>
        </div>
      </div>
      <div class="tlw-p-6">
        <div class="tlw-flex tlw-justify-center">
          <MButton
            variant="primary"
            type="submit"
            :disabled="invalidWithdraw() || total === 0"
            @click="withdraw"
            :label="$t('sparkpay.wallet.withdraw-modal.solicitation.confirm')"
          />
        </div>
      </div>
    </div>
    <LoadingModal v-if="isSolicitation" />
    <ConfirmModal :total="total | currency(true)" v-if="widthdrawCreated && !isSolicitation" />
    <ErrorModal @close="() => (widthdrawError = false)" v-if="widthdrawError && !widthdrawCreated" />
  </div>
</template>

<script>
import LoadingModal from './LoadingModal';
import ConfirmModal from './ConfirmWithdrawModal';
import ErrorModal from './ErrorWithdrawModal';
import { Money } from 'v-money';
import { analyticsService, gatewayService } from '@/services';
import GeneralHelper from '@/shared/helpers/general';
import { mapState } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import MButton from '@/shared/components/MButton.vue';
import sparkpay from '@/sparkpay/services/sparkpay';

const TAX = 4.73;
const MIN_VALUE_WITHDRAW = 20.0;

export default {
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      total: 0,
      isSolicitation: false,
      widthdrawCreated: false,
      widthdrawError: false,
    };
  },
  components: {
    LoadingModal,
    ConfirmModal,
    ErrorModal,
    Money,
    MButton,
  },
  filters: {
    currency(value, showBalance) {
      return showBalance ? GeneralHelper.currency(value, false) : '—';
    },
    bank_name(value) {
      const { name, bank_code } = gatewayService.getBank(value);
      return `${bank_code} - ${name}`;
    },
    bank_account(value, dv, mask = true) {
      return `${GeneralHelper.bankAccount(value, mask)}-${dv}`;
    },
    cpf(value) {
      return GeneralHelper.cpf(value);
    },
    cnpj(value) {
      return GeneralHelper.cnpj(value);
    },
  },
  methods: {
    invalidWithdraw() {
      const available = Number((this.accountData.available - TAX).toFixed(2));
      const withdrawIsBiggerThanAvailable = this.total > available;
      const notAvailableBalance = this.accountData.available === 0;
      const lessThanMinValue = this.total < MIN_VALUE_WITHDRAW;

      return withdrawIsBiggerThanAvailable || notAvailableBalance || lessThanMinValue;
    },
    async withdraw() {
      this.isSolicitation = true;

      sparkpay
        .withdraw({
          id: this.accountData.bank_account.id,
          amount_cents: this.total * 100,
        })
        .then(res => {
          if (res) {
            this.widthdrawCreated = true;
            analyticsService.track({
              event: 'Withdrawal requested',
              props: {
                recipient_id: this.accountData.recipient_id,
                value: this.total,
                school_id: this.selectedSchool.id,
              },
            });
          }
        })
        .catch(error => {
          if (error.response) {
            ToastHelper.dangerMessage(error.response.data?.errors);
          }
        })
        .finally(() => {
          this.isSolicitation = false;
        });
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    calculatedAvailable() {
      return this.accountData.available - TAX;
    },
    calculatedCost() {
      return this.total && TAX;
    },
    calculatedOutflow() {
      return (this.total || 0) + TAX;
    },
    minValueWithdraw() {
      return MIN_VALUE_WITHDRAW;
    },
  },
};
</script>

<template>
  <div>
    <div class="tlw-text-white tlw-text-center tlw-p-12 tlw-bg-green-500">
      <h1 class="display-4 tlw-font-light tlw-mb-2"><hs-icon icon="check-circle" /></h1>
      <h2>{{ $t('sparkpay.wallet.withdraw-modal.success.title') }}</h2>
      <p class="tlw-m-0">{{ $t('sparkpay.wallet.withdraw-modal.success.subtitle') }}</p>
    </div>
    <div class="tlw-pt-6 tlw-pb-12">
      <div class="tlw-border-bottom tlw-px-12 tlw-pb-6 tlw-mb-6">
        <div class="tlw-flex tlw-justify-between tlw-items-center tlw-mb-6">
          <p class="tlw-mb-0">{{ $t('sparkpay.wallet.withdraw-modal.success.date') }}</p>
          <p class="tlw-mb-0 tlw-font-bold">{{ new Date() | date }}</p>
        </div>
        <div class="tlw-flex tlw-justify-between tlw-items-center">
          <p class="tlw-mb-0">{{ $t('sparkpay.wallet.withdraw-modal.success.value') }}</p>
          <p class="tlw-mb-0 tlw-font-bold">R$ {{ total }}</p>
        </div>
      </div>
      <div class="tlw-px-12">
        <hs-button
          block
          variant="success"
          type="button"
          @click="
            () => {
              $bvModal.hide('withdraw-solicitation');
              $router.go('sparkpay/wallet');
            }
          "
        >
          {{ $t('actions.ok') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    total: {
      type: String,
      required: true,
    },
  },
  filters: {
    date(value) {
      return dayjs(value).format('DD/MM/YYYY [às] HH:mm');
    },
  },
};
</script>

<template>
  <div class="card-container">
    <div class="card balance large">
      <div class="card-content">
        <div class="card-content-title">
          <h5 class="tlw-font-bold">Saldo</h5>
          <MButton variant="link" size="sm" @click="() => (showBalance = !showBalance)">
            <hs-icon class="icon-color-wallet" variant="light" :icon="`eye${showBalance ? '' : '-slash'}`" />
          </MButton>
        </div>
        <div v-if="isLoading">
          <hs-placeholder animated width="100px" height="30px" />
          <br />
          <hs-placeholder animated width="180px" height="14px" />
        </div>
        <div v-else>
          <h3
            class="amount"
            :class="{
              'balance-warn': !canRequestWithdrawn,
              'balance-text': showBalance && recipient.available_balance_cents > 0,
            }"
          >
            {{ showBalance ? 'R$' : '' }}
            <span class="tlw-inline-flex tlw-gap-2 tlw-items-center tlw-font-bold">
              {{ currency(recipient.available_balance_cents) }}

              <hs-icon v-if="!canRequestWithdrawn" variant="light" icon="lock" :size="24" />
            </span>
          </h3>
          <p class="financial-reserve" v-if="!canRequestWithdrawn">
            Reserva financeira R$ <strong> {{ currency(recipient.chargeback_locked_value_cents) }}</strong>
          </p>
        </div>
      </div>
      <MButton
        v-if="canRequestWithdrawn && bankAccount"
        class="btn-solicitation"
        variant="primary"
        id="btn-withdraw-solicitation"
        :label="$t('sparkpay.wallet.request-withdrawn')"
        @click="showModal('withdraw-solicitation')"
      />
      <div class="tlw-flex" v-b-tooltip.top.bottom="tooltipDisabledLabel()" v-else>
        <MButton
          class="btn-solicitation tlw-w-full"
          variant="primary"
          id="btn-withdraw-solicitation"
          :label="$t('sparkpay.wallet.request-withdrawn')"
          @click="showModal('withdraw-solicitation')"
          :disabled="!canRequestWithdrawn || !bankAccount"
        />
      </div>
    </div>
    <div class="card receivable">
      <div class="card-content">
        <h5 class="tlw-mb-1 tlw-text-sm">{{ $t('sparkpay.wallet.receivable') }}</h5>
        <template v-if="!isLoading">
          <h4
            class="tlw-text-sm tlw-mb-1"
            v-if="showBalance"
            :class="{ 'text-info': recipient.unavailable_balance_cents > 0 }"
          >
            R$
            <span class="tlw-font-bold">
              {{ currency(recipient.unavailable_balance_cents) }}
            </span>
          </h4>
          <h4 v-else>—</h4>
          <p class="tlw-text-xs">
            Valores futuros que você vai receber.
          </p>
        </template>
        <template v-else>
          <hs-placeholder animated width="100px" height="14px" />
        </template>
      </div>
    </div>
    <div class="card total-withdrawn">
      <div class="card-content">
        <h5 class="tlw-mb-1 tlw-text-sm">{{ $t('sparkpay.wallet.total-withdrawn') }}</h5>
        <template v-if="!isLoading">
          <h4 class="tlw-text-sm tlw-mb-1" v-if="showBalance">
            R$
            <span class="tlw-font-bold">
              {{ currency(recipient.available_balance_to_withdrawal) }}
            </span>
          </h4>
          <h4 v-else>—</h4>
          <p class="tlw-text-xs">
            Valor já retirado do seu saldo
          </p>
        </template>
        <template v-else>
          <hs-placeholder animated width="100px" height="14px" />
        </template>
      </div>
    </div>
    <div class="card antecipation">
      <div class="card-content">
        <h5 class="tlw-mb-1">
          Disponível para antecipação
          <MButton
            class="button-icon"
            variant="link"
            size="sm"
            v-b-tooltip.top.bottom="
              'Caso você não tenha permissão para antecipar, entre em contato com o nosso suporte. '
            "
          >
            <hs-icon class="icon-color-wallet" variant="light" icon="exclamation-circle" />
          </MButton>
        </h5>
        <template v-if="!isLoading">
          <h4 class="tlw-text-sm tlw-mb-1" v-if="showBalance">
            R$
            <span class="tlw-font-bold">
              {{ currency(recipient.anticipation_value_cents) }}
            </span>
          </h4>
          <h4 v-else>—</h4>

          <MButton
            v-if="recipient.allow_anticipation"
            variant="primary-outline"
            size="sm"
            :disabled="!allowedAnticipation"
            class="wallet-balance-info__anticipation-button"
            @click="openAnticipationModal"
          >
            Antecipar
          </MButton>
          <MButton
            v-else
            variant="primary-outline"
            size="sm"
            class="wallet-balance-info__anticipation-button"
            :disabled="!allowedAnticipation"
            @click="openVerifyAnticipationModal"
          >
            Verificar
          </MButton>
        </template>
        <template v-else>
          <hs-placeholder animated width="100px" height="14px" />
        </template>
      </div>
    </div>
    <div class="card financial-reserve">
      <div class="card-content">
        <h5 class="tlw-mb-1">
          Reserva financeira
          <MButton
            class="button-icon"
            variant="link"
            size="sm"
            v-b-tooltip.top.bottom="
              'A Reserva financeira é uma porcentagem do valor de saldo disponível + valor a receber.'
            "
          >
            <hs-icon class="icon-color-wallet icon-color-wallet--2-icon" variant="light" icon="exclamation-circle" />
          </MButton>
        </h5>
        <template v-if="!isLoading">
          <h4 class="tlw-text-sm tlw-mb-1" v-if="showBalance">
            R$
            <span class="tlw-font-bold">
              {{ currency(recipient.chargeback_locked_value_cents) }}
            </span>
          </h4>
          <h4 v-else>—</h4>
          <p class="font-size-xs tlw-text-gray-500 tlw-mt-2">
            A Reserva Financeira é usada para cobrir reembolsos e/ou chargebacks.
          </p>
        </template>
        <template v-else>
          <hs-placeholder animated width="100px" height="14px" />
        </template>
      </div>
    </div>

    <hsModal
      v-if="!isLoading && recipient.available_balance_cents > 0 && bankAccount"
      :id="modalId.withdraw"
      size="md"
      :hideHeader="true"
      bodyClass="tlw-p-0"
    >
      <WithdrawModal
        :account-data="{
          available: (recipient.available_balance_cents - recipient.chargeback_locked_value_cents) / 100,
          bank_account: bankAccount,
          recipient_id: recipient.id,
        }"
      />
    </hsModal>

    <AnticipationModal
      v-if="displayAnticipationModal"
      :max-anticipation-value-cents="recipient.anticipation_value_cents"
      :bank-account-id="bankAccount.id"
      @close="closeAnticipationModal"
      @success-anticipation="handleSuccessAnticipation"
      @error-anticipation="handleErrorAnticipation"
    />
    <VerifyAnticipationModal
      v-else
      @success-request="handleSuccessVerifyAnticipation"
      @close="closeVerifyAnticipationModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GeneralHelper from '@/shared/helpers/general';
import toastHelper from '@/shared/helpers/toast';
import BlipchatService from '@/services/blipchat';

import MButton from '@/shared/components/MButton.vue';
import { hsModal } from '@/components';
import WithdrawModal from '../components/WithdrawModal.vue';
import AnticipationModal from './AnticipationModal.vue';
import VerifyAnticipationModal from './VerifyAnticipationModal.vue';

export default {
  name: 'WalletBalanceInfo',
  components: {
    MButton,
    hsModal,
    WithdrawModal,
    AnticipationModal,
    VerifyAnticipationModal,
  },
  props: {
    recipient: {
      type: Object,
      required: true,
    },
    bankAccount: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalId: {
        withdraw: 'withdraw-solicitation',
        bankAccount: 'create-bank-account',
      },
      showBalance: true,
      isUpdating: false,
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('plan', ['actualPlan']),
    canRequestWithdrawn() {
      return (
        this.recipient.available_balance_cents > 0 &&
        this.recipient.available_balance_cents > this.recipient.chargeback_locked_value_cents
      );
    },
    allowedAnticipation() {
      const hasAnticipationBalance = this.recipient?.anticipation_value_cents > 0;
      const hasBankAccount = this.bankAccount?.id;

      return Boolean(hasAnticipationBalance && hasBankAccount);
    },
    displayAnticipationModal() {
      return this.recipient?.allow_anticipation && this.allowedAnticipation;
    },
  },
  methods: {
    currency(value) {
      return this.showBalance ? GeneralHelper.currency(value / 100, false) : '—';
    },
    handleSuccessAnticipation() {
      this.closeAnticipationModal();
      this.$emit('success-anticipation');

      toastHelper.successMessage(
        'Antecipação solicitada',
        'Estamos analisando a sua antecipação, confira os saques para saber se foi aprovada.'
      );
    },
    handleErrorAnticipation() {
      toastHelper.dangerMessage(
        'Erro ao solicitar antecipação',
        'Tente novamente mais tarde ou entre em contato com o suporte.'
      );
    },
    handleSuccessVerifyAnticipation() {
      this.closeVerifyAnticipationModal();
      this.openSupportChat();
    },
    openAnticipationModal() {
      this.showModal('anticipation-modal');
    },
    closeAnticipationModal() {
      this.$bvModal.hide('anticipation-modal');
    },
    openVerifyAnticipationModal() {
      this.$bvModal.show('verify-anticipation-modal');
    },
    closeVerifyAnticipationModal() {
      this.$bvModal.hide('verify-anticipation-modal');
    },
    openSupportChat() {
      BlipchatService.open(this.selectedSchool, this.actualPlan);
    },
    tooltipDisabledLabel() {
      if (!this.bankAccount) {
        return 'Você precisa cadastrar uma conta bancária para solicitar saque.';
      }
      return 'Seu saldo está bloqueado porque precisa ser maior que a reserva financeira + taxa de saque.';
    },
    showModal(id) {
      this.$bvModal.show(id);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

/deep/ .tooltip-inner {
  border-radius: 4px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    min-height: 126px;

    &.large {
      grid-row: span 2;
    }

    &.balance {
      display: flex;
      justify-content: space-between;

      .card-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .card-content-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          font-size: 16px;
        }
      }

      h3 {
        font-size: 32px;
      }
    }

    h5 {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
    }

    .card-content {
      p {
        color: #6f6f6f;
      }
    }
  }
}
.button-icon {
  padding: 0;
}
.icon-color-wallet {
  color: #1e4278;
}

.balance-text {
  color: #348535;
}

.balance-warn {
  color: #bf8208;
}

.financial-reserve {
  color: #3f3f3f;
}

@media (max-width: 1200px) {
  .balance #btn-withdraw-solicitation {
    margin-top: 48px;
  }
  .card-container {
    grid-template-columns: repeat(2, 1fr);

    .card.large {
      grid-column: span 2;
      width: auto;
    }
  }
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: 1fr;

    .card.large {
      grid-column: span 1;
      grid-row: auto;
      width: auto;
    }
  }
}

.wallet-balance-info__anticipation-button {
  margin-top: 8px;
}
</style>

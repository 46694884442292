<template>
  <div>
    <WalletBalanceInfo
      :recipient="recipient"
      :is-loading="isLoadingRecipient"
      :bank-account="bankAccount"
      @success-anticipation="fetchRecipient()"
    />
    <InfoTax v-if="recipient" :recipient="recipient" />
  </div>
</template>

<script>
import toastHelper from '@/shared/helpers/toast';
import sparkpayService from '@/sparkpay/services/sparkpay';

import InfoTax from '../components/InfoTax.vue';
import WalletBalanceInfo from '../components/WalletBalanceInfo.vue';

export default {
  name: 'WalletBalance',
  components: {
    InfoTax,
    WalletBalanceInfo,
  },
  data() {
    return {
      recipient: {},
      bankAccount: {},
      isLoadingRecipient: false,
    };
  },
  methods: {
    async fetchRecipient() {
      this.isLoadingRecipient = true;

      try {
        this.recipient = await sparkpayService.recipient();
      } catch (error) {
        toastHelper.dangerMessage('Problema ao carregar seus dados, se persistir entre em contato com o suporte');
      } finally {
        this.isLoadingRecipient = false;
      }
    },
    async fetchBankAccount() {
      try {
        const { bank_accounts } = await sparkpayService.bankAccount.list();
        const filteredBankAccount = bank_accounts.filter(bankAccount => bankAccount.bank_account_type !== 'pix');

        this.bankAccount = filteredBankAccount[0] || null;
      } catch (error) {
        toastHelper.dangerMessage('Problema ao carregar seus dados, se persistir entre em contato com o suporte');
      }
    },
  },
  mounted() {
    Promise.all([this.fetchRecipient(), this.fetchBankAccount()]);
  },
};
</script>

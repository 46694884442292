<template>
  <Modal
    id="verify-anticipation-modal"
    content-class="verify-anticipation-modal-content"
    body-class="verify-anticipation-modal-body"
    hide-header
    no-close-on-esc
    @show="resetCheckValues"
  >
    <MButton variant="light" icon="times" class="verify-anticipation-modal__close-button" @click="$emit('close')" />

    <div class="verify-anticipation-modal__content">
      <div class="verify-anticipation-modal__title-wrapper">
        <h5 class="verify-anticipation-modal__title" data-testid="verify-anticipation-modal-title">
          Solicitação de antecipação
        </h5>

        <p class="verify-anticipation-modal__subtitle">
          A antecipação de recebíveis permite receber os valores das vendas no cartão de crédito antes do prazo
          previsto.
        </p>

        <p class="verify-anticipation-modal__subtitle">
          Para saber se você pode pedir antecipação, verifique se cumpre os itens abaixo.
        </p>
      </div>

      <div class="verify-anticipation-modal__checkboxes-wrapper">
        <MXInputCheckbox
          id="sales-history-checkbox"
          data-testid="checkbox-input-anticipation"
          v-model="checks.salesHistory.value"
          :label="checks.salesHistory.label"
        />

        <MXInputCheckbox
          id="low-chargeback-index-checkbox"
          data-testid="checkbox-input-anticipation"
          v-model="checks.lowChargebackIndex.value"
          :label="checks.lowChargebackIndex.label"
        />
      </div>

      <div class="verify-anticipation-modal__footer">
        <div class="verify-anticipation-modal__faq">
          <hs-icon variant="light" icon="info-circle" size="24" />

          <p>
            Para saber mais sobre a antecipação e tirar demais dúvidas,
            <a
              href="https://ajuda.herospark.com/hc/pt-br/articles/6019620182669-O-que-%C3%A9-antecipa%C3%A7%C3%A3o-e-como-solicitar"
              target="_blank"
            >
              consulte nossa FAQ.
            </a>
          </p>
        </div>

        <div class="verify-anticipation-modal__buttons-wrapper">
          <MButton variant="primary-outline" class="verify-anticipation-modal__cancel-button" @click="$emit('close')">
            Cancelar
          </MButton>

          <MButton
            variant="primary"
            class="verify-anticipation-modal__send-request-button"
            :disabled="!isAllCheckboxesChecked"
            label="Enviar solicitação"
            @click="$emit('success-request')"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import MButton from '@/shared/components/MButton.vue';
import MXInputCheckbox from '@/shared/components/MXInputCheckbox.vue';

export default {
  name: 'VerifyAnticipationModal',
  components: {
    Modal,
    MButton,
    MXInputCheckbox,
  },
  data() {
    return {
      checks: {
        salesHistory: {
          label: 'Histórico de vendas superior a 90 dias na HeroSpark',
          value: false,
        },
        lowChargebackIndex: {
          label: 'Índice de Chargeback baixo',
          value: false,
        },
      },
    };
  },
  computed: {
    isAllCheckboxesChecked() {
      return Object.values(this.checks).every(check => check.value);
    },
  },
  methods: {
    resetCheckValues() {
      Object.values(this.checks).forEach(check => {
        check.value = false;
      });
    },
  },
};
</script>

<style lang="scss">
.verify-anticipation-modal-content {
  max-width: 448px;
}

.modal .verify-anticipation-modal-body {
  padding: 16px;

  @media (min-width: $screen-md) {
    padding: 32px 24px;
  }
}

.verify-anticipation-modal__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $grey;
}

.verify-anticipation-modal__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: $screen-md) {
    row-gap: 24px;
  }
}

.verify-anticipation-modal__title-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.verify-anticipation-modal__title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.563rem;
  text-align: center;
}

.verify-anticipation-modal__subtitle {
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin: 0;
}

.verify-anticipation-modal__checkboxes-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.verify-anticipation-modal__footer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: $screen-md) {
    row-gap: 32px;
  }
}

.verify-anticipation-modal__faq {
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 16px;
  background-color: $grey-16;
  border-radius: 4px;
  color: $color-neutral-600;

  p {
    font-size: 0.875rem;
    line-height: 1.313rem;
    margin: 0;
  }

  a {
    font-size: 0.875rem;
    color: $purple-dark;
    text-decoration: underline;
  }
}

.verify-anticipation-modal__buttons-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media (min-width: $screen-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.verify-anticipation-modal__cancel-button {
  order: 2;

  @media (min-width: $screen-md) {
    order: 1;
  }
}

.verify-anticipation-modal__send-request-button {
  order: 1;

  @media (min-width: $screen-md) {
    order: 2;
  }
}
</style>

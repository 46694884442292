<template>
  <div class="tlw-flex tlw-justify-center m-6">
    <div class="spinner-tlw-border text-cherry font-size-xs">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<template>
  <div>
    <div class="tlw-text-white tlw-text-center tlw-p-12 bg-cherry">
      <h1 class="display-4 tlw-font-light tlw-mb-2"><hs-icon icon="times-circle" /></h1>
      <h2>{{ $t('sparkpay.wallet.withdraw-modal.error.title') }}</h2>
      <p class="tlw-m-0">{{ $t('sparkpay.wallet.withdraw-modal.error.subtitle') }}</p>
    </div>
    <div class="tlw-p-12">
      <hs-button
        block
        variant="cherry"
        type="button"
        @click="
          () => {
            $bvModal.hide('withdraw-solicitation');
            $emit('close', true);
          }
        "
      >
        {{ $t('actions.ok') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<template>
  <section class="info-tax">
    <h4 class="tlw-font-bold">Taxas e Recebimentos</h4>
    <p class="font-size-sm tlw-text-gray-500 tlw-mt-2">
      Toda vez que você realiza uma venda, são descontadas algumas taxas de acordo com o valor transacionado e o meio de
      pagamento.
    </p>

    <section class="info-tax__cards-container">
      <section class="info-tax__card">
        <h5>
          <hs-icon variant="light" icon="coins" class="icon-color" />
          Saque
        </h5>
        <h5 class="tlw-mt-6" data-testid="withdrawal-fee">
          <strong>{{ formatCentsToCurrency(recipient.fix_withdrawal_fee) }}</strong> fixa
        </h5>
        <p class="info-tax__card-text">
          Em cada saque realizado, é cobrada essa taxa fixa.
        </p>
      </section>

      <section class="info-tax__card">
        <h5>
          <hs-icon variant="light" icon="credit-card" class="icon-color" />
          Cartão de Crédito
        </h5>
        <h5 class="tlw-mt-6">
          <strong>{{ recipient.percentage_transaction_fee }}%</strong> +
          {{ formatCentsToCurrency(recipient.fix_transaction_fee) }}
        </h5>
        <p class="info-tax__card-text">
          Para transações igual ou acima de {{ formatCentsToCurrency(credit_card_cents) }}
        </p>

        <p class="info-tax__card-text">
          {{ percentage_transaction }}% + {{ formatCentsToCurrency(recipient.fix_transaction_fee) }} sobre transações de
          valor inferior a {{ formatCentsToCurrency(credit_card_cents) }}
        </p>
      </section>

      <section class="info-tax__card">
        <h5>
          <hs-icon variant="light" icon="badge-dollar" class="icon-color" />
          Pix
        </h5>
        <h5 class="tlw-mt-6">
          <strong>{{ recipient.pix_percentage_transaction_fee }}%</strong> +
          {{ formatCentsToCurrency(recipient.fix_pix_fee) }}
        </h5>
        <p class="info-tax__card-text">
          Para transações igual ou acima de {{ formatCentsToCurrency(credit_card_cents) }}
        </p>

        <p class="info-tax__card-text">
          {{ percentage_transaction }}% + {{ formatCentsToCurrency(recipient.fix_pix_fee) }} sobre transações de valor
          inferior a {{ formatCentsToCurrency(credit_card_cents) }}
        </p>

        <p class="info-tax__card-text">
          *O valor mínimo para o Pix é de {{ formatCentsToCurrency(min_value_percentage_cents) }}
        </p>
      </section>
    </section>

    <section class="info-tax__cards-container info-tax__cards-container--2-columns">
      <section class="info-tax__card">
        <h5>
          <hs-icon variant="light" icon="barcode-alt" class="icon-color" />
          Boleto Bancário
        </h5>
        <h5 class="tlw-mt-6">
          <strong>{{ recipient.bank_slip_percentage_transaction_fee }}%</strong> +
          {{ formatCentsToCurrency(recipient.fix_bank_slip_fee) }}
        </h5>
        <p class="info-tax__card-text">
          Para transações igual ou acima de {{ formatCentsToCurrency(credit_card_cents) }}
        </p>

        <p class="info-tax__card-text">
          {{ percentage_transaction }}% + {{ formatCentsToCurrency(recipient.fix_bank_slip_fee) }} sobre transações de
          valor inferior a {{ formatCentsToCurrency(credit_card_cents) }}
        </p>

        <p class="info-tax__card-text">
          *O valor mínimo para o boleto é de {{ formatCentsToCurrency(min_value_ticket_cents) }}
        </p>
      </section>

      <section class="info-tax__card">
        <h5>
          <hs-icon variant="light" icon="divide" class="icon-color" />
          Parcelamento
        </h5>
        <h5 class="tlw-mt-6" data-testid="intallment-fee-text">
          <strong>{{ recipient.percentage_per_installment_fee }}%</strong> ao mês
        </h5>
        <p class="info-tax__card-text">
          Valor mínimo da parcela é de {{ formatCentsToCurrency(min_value_percentage_cents) }}
        </p>

        <p class="info-tax__card-text">
          *A taxa de parcelamento pode ser repassada ao comprador
        </p>
      </section>
    </section>
    <h5 class="tlw-font-bold">
      <a
        class="text-purple tlw-underline"
        href="https://ajuda.herospark.com/hc/pt-br/articles/4407774770573-Quais-s%C3%A3o-as-tarifas-de-venda-"
        target="_blank"
      >
        Saiba mais sobre as suas tarifas de venda
      </a>
    </h5>
  </section>
</template>

<script>
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'InfoTax',
  props: {
    recipient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      credit_card_cents: 1000,
      min_value_ticket_cents: 500,
      min_value_percentage_cents: 199,
      percentage_transaction: 20,
    };
  },
  methods: {
    formatCentsToCurrency(valueInCents) {
      const value = valueInCents / 100;
      return value ? GeneralHelper.currency(value) : 'R$ 0,00';
    },
  },
};
</script>

<style lang="scss">
.page__content {
  background: #e3e3e3;
}

.icon-color {
  color: #1e4278;
}

.info-tax__card-text {
  font-size: 0.75rem;
  color: #6f6f6f;
  line-height: 1.125rem;
  margin: 0;
  margin-top: 8px;
}

.info-tax__cards-container {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.info-tax__cards-container--2-columns {
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 29px;
}

.info-tax__card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #fff;
  padding: 16px;
}

@media screen and (max-width: 484px) {
  .info-tax__cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }
}

.info-tax {
  margin-top: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;

  @media (min-width: $screen-md) {
    padding: 24px;
  }
}
</style>
